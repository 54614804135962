<template>
	<v-row no-gutters align="center">

		<v-menu :close-on-content-click="false" rounded="lg">
			<template v-slot:activator="{ on }">
				<v-btn color="cell3" class="px-0 overflow-hidden rounded-circle" v-on="on" small style="min-width: 20px; max-width: 20px; min-height: 20px; max-height: 20px">
					<div :style="{backgroundColor: value}" style="width: 14px; height: 14px; border-radius: 50%"></div>
				</v-btn>
			</template>
			<v-color-picker v-model="val" mode="hexa" @input="(e) => new_val = e"  hide-sliders />
		</v-menu>
	</v-row>
</template>

<script>
	export default {
		name: "ColorField",
		props: ['value'],
		data(){
			return {
				val: ''
			}
		},
		computed: {
			new_val: {
				get(){
					return this.value || ''
				},
				set(val){
					this.$emit('input', val)
				}
			}
		},
		watch: {
			val(val) {
				if (val.length > 7){
					this.val = val.substr(0,7)
				}
			}
		},
		created(){
			this.val = this.new_val
		}
	}
</script>

<style scoped>

</style>